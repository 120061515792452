import CGIcon from '../socials/coingecko';
import GithubIcon from '../socials/github';
import TelegramIcon from '../socials/telegram';
import TwitterIcon from '../socials/twitter';

const navigation = [
	// { name: 'About', href: '/about' },
	{ name: "Blog", href: "/blog" },
	{ name: "Docs", href: "https://docs.astrolescent.com/" },
	{ name: "Analytics", href: "/stats" },
	// { name: "Portfolio", href: "/portfolio" },

];

export default function Footer() {
   return (
		<>
			<footer className="relative flex flex-col items-center justify-center space-y-6 px-4 pb-8 pt-20 md:flex-row md:justify-between md:space-x-4 md:space-y-0">
				<div className="flex items-center space-x-4 text-sm">
					{navigation.map((item) => (
						<p key={item.name} className="text-white hover:underline">
							<a href={item.href}>{item.name}</a>
						</p>
					))}
				</div>
				<div className="flex items-center space-x-4">
					<p className="text-white hover:text-black">
						<a href="https://t.me/astrolescent_official" target="_blank">
							<TelegramIcon />
						</a>
					</p>
					<p className="text-white hover:text-black">
						<a href="https://twitter.com/Astrolescent" target="_blank">
							<TwitterIcon />
						</a>
					</p>
					<p className="text-white hover:text-black">
						<a href="https://github.com/Astrolescent-Official" target="_blank">
							<GithubIcon />
						</a>
					</p>
					<p className="text-white hover:text-black">
						<a href="https://www.coingecko.com/en/coins/astrolescent" target="_blank">
							<CGIcon />
						</a>
					</p>
				</div>
			</footer>
			<p className="mt-6 flex items-center justify-center space-x-4 text-sm text-white">
				<a href="https://www.radixdlt.com/ecosystem-directory" className="hover:underline">
					<img src="/assets/img/radix/Long-White-Monochrome-outline.png" className="h-6" />
				</a>
				<a href="https://radquest.io" target="_blank" className="hover:underline">
					New to Radix?
				</a>
			</p>
		</>
	);
}
