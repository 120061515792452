import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink } from '@remix-run/react';
import { useRdt } from '~/rdt/hooks/useRdt';
import { classNames } from '~/lib/util';

declare global {
   namespace JSX {
      interface IntrinsicElements {
         'radix-connect-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
   }
}

const navigation = [
	{ name: "Swap", href: "/" },
	{ name: "Bridge", href: "/bridge" },
	{ name: "Stake", href: "/staking" },
	{ name: "Portfolio", href: "/portfolio" },

	// { name: 'About', href: '/about' },
	// { name: 'Blog', href: '/blog' },
	// { name: 'Docs', href: 'https://docs.astrolescent.com/' },
	// { name: 'Analytics', href: '/stats' },
	// { name: 'Tokens', href: '/tokens' },
];

export default function Header() {
   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
   const rdt = useRdt()

   // set button layout
   useEffect(() => {
      rdt?.buttonApi.setTheme('white');
      rdt?.buttonApi.setMode('light');
   }, []);

   return (
		<header className="shadow-lg ring-1 ring-black/5">
			<nav className="_backdrop-blur flex items-center justify-between gap-x-6 rounded-b-lg rounded-t-lg bg-white/30 p-2 md:rounded-b-none" aria-label="Global">
				<div className="flex md:hidden">
					<button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-200" onClick={() => setMobileMenuOpen(true)}>
						<span className="sr-only">Open main menu</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>

				<NavLink to="/" className="block md:hidden">
					<img className="mx-auto w-3/4 max-w-80 py-3" src="/assets/img/logo-named.png" alt="Astrolescent logo" />
				</NavLink>

				<div className="hidden md:flex md:gap-x-2">
					{navigation.map((item) => (
						<NavLink
							key={item.name}
							to={item.href}
							className={({ isActive, isPending }) =>
								classNames(isActive ? "bg-white text-black" : "text-white", "font-optical-24-550 tracking-astro h-8 rounded px-2 text-xs uppercase leading-8 hover:bg-white hover:text-black")
							}
						>
							{item.name}
						</NavLink>
					))}
				</div>
				<radix-connect-button />
			</nav>
			<div className="mask-ticker _backdrop-blur hidden h-8 items-center overflow-hidden bg-black/50 font-mono text-xs uppercase text-white md:flex md:space-x-2">
				<p className="ticker1 whitespace-nowrap">Astrolescent is your one-stop-trading shop, always getting you the best price for every trade.</p>
				<p className="ticker2 whitespace-nowrap">&nbsp;Astrolescent is your one-stop-trading shop, always getting you the best price for every trade.</p>
			</div>

			<NavLink to="/" className="_backdrop-blur hidden rounded-b-lg bg-white/30 p-2 md:block">
				<img className="mx-auto w-1/2 py-3" src="/assets/img/logo-named.png" alt="Astrolescent logo" />
			</NavLink>

			<Dialog as="div" className="md:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
				<div className="fixed inset-0" />
				<Dialog.Panel className="fixed inset-y-0 right-0 z-[1001] w-full overflow-y-auto bg-black/50 p-6 text-white backdrop-blur-lg sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div className="flex items-center gap-x-6">
						<NavLink to="/">
							<span className="sr-only">Astrolescent</span>
							<img className="h-8 w-auto" src="/assets/img/shortcut-icon-256x256.png" alt="Astrolescent logo" />
						</NavLink>
						{/* <NavLink
                     to="/swap"
                     className="ml-auto rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                     Start trading
                  </NavLink> */}
						<button type="button" className="-m-2.5 ml-auto rounded-md p-2.5 text-slate-200" onClick={() => setMobileMenuOpen(false)}>
							<span className="sr-only">Close menu</span>
							<XMarkIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="mt-6 flow-root">
						<div className="-my-6 divide-y divide-black/10">
							<div className="space-y-2 py-6">
								{navigation.map((item) => (
									<NavLink key={item.name} to={item.href} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7" onClick={() => setMobileMenuOpen(false)}>
										{item.name}
									</NavLink>
								))}
							</div>
							{/* <div className="py-6">
                        <a href="/" className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                           Log in
                        </a>
                     </div> */}
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	);
}
